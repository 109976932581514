.App {
  text-align: center;
  background-color: #282c34;
}
.App-content{
  background-color: #282c34;
  min-height: 100vh;
  display: contents;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F2EDEB;
}
.navbar-brand {
  display: flex;
  justify-content: center;
  padding-bottom: 0.3125rem;
  font-size: 1.25rem;
  flex-direction: column;
  padding: 0;
  margin:0;
  line-height: inherit;
}
.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


button {
  font-size: 18px;
}
input {
  font-size: 18px;
}

h1 {
  margin: 10px;
  font-size: 40px;
  color: rgb(1, 1, 59);
}

ul li {
  font-size: 20px;
}
bold {
  font-weight: 550;
}

.textColorRed {
  color: #FF0057
}

.main-container{
  align-items: center;
  min-width: inherit;
  display: flex;
  flex-direction: column;
  min-height: inherit;
  margin-top: 0;
}

.downloadChrome {
  background-image: linear-gradient(92.88deg, #F2EDEB 9.16%, #F2EDEB 43.89%, #F2EDEB 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #1D201F;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-block: 2rem;
}

.downloadChrome:hover {
  text-decoration: none;
}

/* Custom Input style */
input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: inherit;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #2497E3;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #A1D0FF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2497E3;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #2497E3;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #A1D0FF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2497E3;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #2497E3;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #A1D0FF;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #2497E3;
}
input[type=range]:focus::-ms-fill-upper {
  background: #2497E3;
}

/* Custom container style */
.beetested-container {
  background-color: #1D201F;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0 50px 0;
}

/* Custom form style override */
.form {
  margin-top: 50px;
}

.form > .question > textarea {
  width: 100%;
}

.form > .question > .slider-container {
  max-width: 350px;
}

/* Custom buttons Style */
button{
  background-image: linear-gradient(92.88deg, #FF0057 9.16%, #FF0057  43.89%, #FF0057 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #F2EDEB;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  touch-action: manipulation;
}