/* Video frames style */

.videoCompound{
    display: block;
    width: 90%;
}
.permissions{
    display: inline-block;
    margin-top: 0%;

}
/* Recording Buttons */
.btn:disabled{
    opacity: 0.45;
}

.mainbuttons {
    display: block;
    width: 100%;
}

.start-recording {
    background-image: linear-gradient(92.88deg, #FABC2A 9.16%, #FABC2A 43.89%, #FABC2A 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #F2EDEB;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-block: 2rem;
    }

.start-recording:hover {
    box-shadow: #fabc2a  0 1px 64px;
    transition-duration: .1s;
}

.start-recording:disabled {
    box-shadow: none;
    cursor: not-allowed;
}

.stop-recording {
    background-image: linear-gradient(92.88deg, #0000FF 9.16%, #0000FF  43.89%, #0000FF 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #F2EDEB;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-block: 2rem;
    margin-left: 15%;
}

.stop-recording:hover {
box-shadow: #3d3df5   0 1px 30px;
transition-duration: .1s;
}
.stop-recording:disabled {
    box-shadow: none;
    cursor: not-allowed;
}
.hidden-button{
    display: none;
}

@media (min-width: 768px) {
    .btn-recording {
        padding: 0 2.6rem;
    }
}
.permissions .permissionsButton{

    background-image: linear-gradient(92.88deg, #FF0057 9.16%, #FF0057  43.89%, #FF0057 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #F2EDEB;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top:10%;
}
.permissions .permissionsButton:hover {
        box-shadow: #FF0057  0 1px 64px;
        transition-duration: .1s;
    
}
/* Loading css style */

.loading-container{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

h2{
    color:#F2EDEB;
}