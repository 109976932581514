
  .logo-text {
    position: relative;
    top: 22px;
    display: inline-block;
    margin-top: 0px;
    font-size: 40px;
    font-weight: 500;
  }
  
  .logo {
    margin: 20px;
  }

  .color-yellow {
    display: inline-block;
    color: #fabc2a;
  }
  .color-white {
    display: inline-block;
    color: #ffffff;
  }
  .color-blue {
    display: inline-block;
    color: #2941e0;
  }

  .logo-footer {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .logo{
    height: 80px;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #444444;
  }
  
  ul {
    margin: 0;
  }
  .brand{
    margin: auto;
    text-align: center;
  }
  .custom-navbar {
    background-color: #000000;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    align-items: center;
    display: block;
  }

  .navbar-brand {
    margin: auto;
  }

  .navbar-brand .subtitle{
    display: block;
  }
  .container{
   display: inline-block;
  }
  .allow-butt {
    background-image: linear-gradient(92.88deg, #fabc2a 9.16%, #fabc2a 43.89%, #fabc2a 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-block: 2rem;
  }

  .content-section {
    background: #ffffff;
    padding: 10px 20px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    margin-bottom: 20px;
  }