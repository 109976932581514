.loading-container{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.column-1 {
    display: inline-block;
    min-width: 200px;
    max-width: 240px;
    width: 20%;
    top: 0;
}

.column-2 {
    display: inline-block;
    width: 70%;
    margin: 30px;
    vertical-align: top;
    margin-top: 0;
}

.test-container {
    padding: 30px 20px 30px 30px;
    text-align: left;
    border-radius: 20px;
    padding-bottom: 50px;
    vertical-align: top;
    text-align: center;
}

.image-container {
    width: 90%;
    text-align: center;
    border-radius: 10px;
    background-color: #F2EDEB;
    padding: 5%;
}

.image-container > img {
    width: 100%;
    margin: auto;
}

.image-container-mobile {
    display: none;
}

.summary-description {
    width: 85%;
    display: inline-block;
    background-color: #1D201F;
    padding: 10px 20px 20px 20px;
    margin-top: 10px;
    border-radius: 10px;
    text-align: left;
}

.summary-description > .title-container {
    font-size: 24pt;
    font-weight: bold;
    margin-top: 10px;
}

.summary-description > .title-container > .title-2 {
    font-size: 18pt;
}

.error-message {
    background-color: red;
    width: 90%;
    position: absolute;
    bottom: -10px;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.submitButt-container {
    display: flex;
    justify-content: center;
}

.permissions .permissionsButton:hover {
    box-shadow: #FF0057  0 1px 64px;
    transition-duration: .1s;
    
}

.text-description-container {
    background-color: #1D201F;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0 50px 0;
    min-height: 540px;
}

.description-container {
    position: relative;
}

.description-text {
    margin-top: 30px;
    text-align: left;
    margin: 40px 10px 0 20px;
}

.test-completed {
    margin-top: 50px;
}

.range-value {
    position: relative;
    display: block;
    font-size: 24pt;
    font-weight: bold;
    position: relative;
    bottom: 5px;
    margin-right: 10px;
    width: 30px;
}

.slider-container {
    display: flex;
}

.aclaratory {
    font-size: 10pt;
}

@media screen and (max-width: 1344px) {
    .summary-description > .title-container {
        font-size: 18pt;
        font-weight: bold;
    }

    .summary-description > .title-container > .title-2 {
        font-size: 14pt;
    }
}

@media screen and (max-width: 1024px) {
    .column-1 {
        max-width: 200px;
    }
    .column-2 {
        max-width: 500px;
    }

    .text-description-container {
        min-height: 550px;
    }

    .description-text {
        font-size: 11pt;
        margin-top: 20px;
    }
    
}

@media screen and (max-width: 768px) {
    .test-container {
        padding-top: 0;
    }

    .image-container {
        display: none;
    }

    .image-container-mobile {
        display: block;
        width: 80%;
        margin: auto;
        text-align: center;
        border: 10px solid #1D201F;
        border-radius: 10px;
        background-color: #F2EDEB;
        padding: 5%;
    }

    .column-1 {
        width: 100%;
        max-width: 100%;
        vertical-align: top;
    }

    .summary-description {
        width: 40%;
        margin: 5px;
        vertical-align: top;
        min-height: 285px;
    }

    .column-2 {
        width: 100%;
        max-width: 100%;
        margin: auto;
        min-height: 0;
    }

    .text-description-container {
        min-height: 0;
    }


    
    .image-container-mobile > img {
        width: 100%;
        margin: auto;
    }
    
}

@media screen and (max-width: 468px) {
    .test-container {
        padding: 0;
    }

    .summary-description {
        width: 85%;
    }

    .description-text {
        font-size: 10pt;
    }
}
